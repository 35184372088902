import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

import {
    Layout,
    PageHeader,
    Testimonial,
    News,
    LoanWidget,
    Disclaimer,
} from '../components'
import * as Shared from '../styles/shared'

const Brokers = props => {
    const data =
        props.previewData ||
        parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log('data', data)

    const breadcrumbs = {
        heading: {
            title: 'Work with Strive',
            link: undefined,
        },
    }

    const renderIntro = () => {
        return (
            <Intro>
                <BGColor className={`bg-color`} />
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.intro_intro_text,
                    }}
                />
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.intro_paragraph_text,
                    }}
                />
            </Intro>
        )
    }

    const renderFeatures = () => {
        let features

        if (data.features_items) {
            features = data.features_items.map((item, i) => {
                return (
                    <Feature key={i}>
                        <Image key={item.icon.id} src={item.icon.url}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}
                        />
                    </Feature>
                )
            })
        }

        return (
            <Features>
                <BGColor className={`bg-color`} />
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.features_heading,
                    }}
                />
                <Grid>{features}</Grid>
            </Features>
        )
    }

    const renderIntroduction = () => {
        return (
            <Wrapper>
                <Introduction>
                    <Grid>
                        <Left>
                            {renderIntro()}
                            {renderFeatures()}
                        </Left>
                        <Right>
                            <LoanWidget size="mini" type="partners" />
                        </Right>
                    </Grid>
                </Introduction>
            </Wrapper>
        )
    }

    const renderMain = () => {
        let extra_links
        let process_list
        let external_link = /^https?:\/\//i

        if (data.extra_links_display) {
            extra_links = data.extra_links.map((item, i) => {
                // external link
                if (external_link.test(item.link)) {
                    return (
                        <ExternalLink href={`${item.link}`} target={'_blank'}>
                            {item.title}
                        </ExternalLink>
                    )
                } else {
                    return (
                        <Button key={i} to={`${item.link}`}>
                            {item.title}
                        </Button>
                    )
                }
            })
        }

        if (data.process_list) {
            process_list = data.process_list.map((item, i) => {
                return (
                    <Process key={i}>
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: item.heading,
                            }}
                        />
                        {item.short_text && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: item.short_text,
                                }}
                            />
                        )}
                    </Process>
                )
            })
        }

        return (
            <Main>
                <Container>
                    <Grid>
                        <Left>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.maintext_heading,
                                }}
                            />
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.maintext_text,
                                }}
                            />
                        </Left>
                        <Right>
                            {extra_links}
                            <ProcessList>
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: data.process_heading,
                                    }}
                                />
                                {process_list}
                            </ProcessList>
                        </Right>
                    </Grid>
                </Container>
            </Main>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            {renderIntroduction()}
            {renderMain()}

            {data.testimonials && <Testimonial testimonials={data.testimonials} category={`broker`} />}
            <Container>
                <LoanWidget type="partners" />
            </Container>
            {data.news_posts && <News news_posts={data.news_posts} />}
            {data.show_disclaimer_text && (
                <Disclaimer disclaimer_text={data.disclaimer_text}/>
            )}
        </Layout>
    )
}

// Shared Child Components

const Heading = Shared.Heading
const Subheading = Shared.Subheading
const Description = Shared.Description
const Info = Shared.Info
const BGColor = Shared.BGColor
const Button = Shared.Button
const Image = Shared.Image
const LoadedImage = Shared.LoadedImage
const ExternalLink = Shared.ExternalLink

// Layout

const Wrapper = Shared.Wrapper
const Container = Shared.Container
const Grid = Shared.Grid
const Left = Shared.Left
const Right = Shared.Right
const Top = Shared.Top
const Bottom = Shared.Bottom

// Top Layout

const Introduction = Shared.Introduction

// Intro

const Intro = Shared.Intro

// Features

const Features = Shared.Features
const Feature = Shared.Feature

// Main

const Main = Shared.Main

// Main Sidebar (Process List)

const ProcessList = Shared.ProcessList
const Process = Shared.Process

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "brokers" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Brokers
